const TitleRowCategory = ({ categoria }) => {
  return (
    <div className="title-category-row d-flex align-items-center ">
      <div className="col col-auto">
        <h3>{categoria}</h3>
      </div>
      <div className="col      ">
        <div className="divider align-self-center  "></div>
      </div>
    </div>
  );
};
export default TitleRowCategory;
