import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  AiFillHome,
  AiOutlineArrowLeft,
  AiOutlineMenu,
  AiOutlineClose,
  AiFillStar,
} from "react-icons/ai";
import { CiMenuBurger } from "react-icons/ci";
import { BsPersonVcard } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

const NavBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showOC, setShowOC] = useState(false);
  const handleSetShowOc = () => setShowOC(!showOC);
  return (
    <>
      <Row className={" navbar-row mt-3 justify-content-center fixed-top "}>
        <Col className=" col-3 px-0 ">
          {pathname !== "/" ? (
            <Button
              onClick={() => navigate("/")}
              variant={"trasparent"}
              className=" button regular-shadow d-flex align-items-center justify-content-center "
            >
              <AiOutlineArrowLeft />
            </Button>
          ) : (
            <div className=" button regular-shadow d-flex align-items-center justify-content-center ">
              <img src="logo.png" alt="" />
            </div>
          )}
        </Col>
        <Col className="col-6    px-0  text-center ">
          <div className=" navbar-title regular-shadow">Scaramuccia</div>
        </Col>
        <Col className=" col-3 px-0 text-end">
          <Button
            onClick={handleSetShowOc}
            variant={"trasparent"}
            className="ms-auto button regular-shadow d-flex align-items-center justify-content-center "
          >
            <CiMenuBurger />
          </Button>
        </Col>
      </Row>
      {/* offcanvas */}
      <div
        className={` offcanvas-overlay offcanvas-full ${
          showOC ? "showOC" : "d-none"
        }`}
      >
        <div className={` ms-auto offcanvas-left px-0 `}>
          <Row className="top-oc justify-content-end">
            <Button
              onClick={handleSetShowOc}
              variant={"trasparent"}
              className="regular-shadow d-flex align-items-center justify-content-center"
            >
              <AiOutlineClose />
            </Button>
          </Row>
          <dl>
            <dt className="border-bottom border-2">Pages</dt>
            <dd
              onClick={() => {
                navigate("/");
                handleSetShowOc();
              }}
              className="d-flex align-items-center"
            >
              <AiFillHome />
              <a href="#top">Home </a>
            </dd>
            <dd
              onClick={() => {
                navigate("/menu");
                handleSetShowOc();
              }}
              className="d-flex align-items-center"
            >
              <AiOutlineMenu />
              <a href="#top">Menu</a>
            </dd>
            <dd
              onClick={() => {
                navigate("/");
                handleSetShowOc();
              }}
              className="d-flex align-items-center"
            >
              <BsPersonVcard />
              <a href="#contact">Contact</a>
            </dd>
            <dd
              onClick={() => {
                navigate("/challenge");
                handleSetShowOc();
              }}
              className="d-flex align-items-center"
            >
              <AiFillStar />
              Scara-Challenge
            </dd>
          </dl>
        </div>
      </div>
    </>
  );
};
export default NavBar;
