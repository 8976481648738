import { Button, Container, Row } from "react-bootstrap";
import GalleryCarousel from "../components/GalleryCarousel";
import { useState } from "react";
import { AiFillCaretRight, AiOutlineMenu, AiFillStar } from "react-icons/ai";
import { AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai";
import { PiMapPinLineLight } from "react-icons/pi";
import { BsTelephone } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const HomePage = () => {
  const navigate = useNavigate();
  const imageList = [
    "ScaramucciaMain.png",
    "granitaSulMare.jpg",
    "drinkSulMare.jpg",
    "https://10619-2.s.cdn12.com/rests/original/103_513469043.jpg",
  ];
  return (
    <>
      <Container className="fade-in main-container p-0  ">
        <span id="top"></span>
        <div className="homepage shadow-lg mb-5">
          <div className="home-hero ">
            <h5 className="text-center">
              <span>bar</span>-<span>food</span>-<span>drink</span>
            </h5>
            <div className="shadow-sm">
              <GalleryCarousel imageList={imageList} />
              <div className="btn-menu-cont d-flex justify-content-center py-2 ">
                <Button
                  onClick={() => navigate("/menu")}
                  variant="trasparent d-flex align-items-center ms-2"
                >
                  <AiOutlineMenu className="me-1" />
                  Menu
                </Button>
              </div>
            </div>

            <a className="d-flex align-items-center">
              <AiFillCaretRight /> Chi siamo?
            </a>
            <section className="box-shadow " id="chiSiamo">
              <p>
                Benvenuti nel mondo di Scaramuccia Food and Drink, il vostro bar
                di riferimento nel cuore del nostro borgo marinaro. La nostra è
                una storia di passione per l'arte della mixology e
                dell'intrattenimento.
              </p>
            </section>
            <a className="d-flex align-items-center ">
              <AiFillCaretRight />
              La Nostra Storia
            </a>
            <section className="box-shadow " id="storia">
              <p>
                Tutto è iniziato molti anni fa quando il nostro fondatore, ha
                deciso di creare un luogo unico dove la gente potesse riunirsi
                per godere di cocktail creativi, birre e una selezione di
                bevande straordinarie, il tutto in un'atmosfera accogliente e
                conviviale.
              </p>
            </section>
            <a className="d-flex align-items-center ">
              <AiFillCaretRight />
              La Nostra Filosofia
            </a>
            <section className="box-shadow " id="filosofia">
              <p>
                Crediamo che un buon bar debba essere più di un semplice luogo
                di consumo di bevande. È un luogo dove gli amici si riuniscono
                per condividere momenti speciali, dove ci si rilassa dopo una
                lunga giornata di lavoro e dove si crea un'atmosfera di festa.
                Siamo orgogliosi di essere un pilastro della nostra comunità, un
                luogo dove ci si sente come a casa e dove si possono fare nuove
                amicizie. Ogni sorriso e ogni momento di gioia che vediamo nei
                nostri clienti ci spinge a migliorare costantemente.
              </p>

              {/* <hr />
                <p>
                  Siamo orgogliosi di essere un pilastro della nostra comunità,
                  un luogo dove ci si sente come a casa e dove si possono fare
                  nuove amicizie. Ogni sorriso e ogni momento di gioia che
                  vediamo nei nostri clienti ci spinge a migliorare
                  costantemente e a offrire un servizio eccezionale.
                </p> */}
            </section>
            <a className="d-flex align-items-center ">
              <AiFillCaretRight />
              La Vostra Esperienza
            </a>
            <section className="box-shadow " id="esperienza">
              <p className="">
                Quando varcate la soglia di Scaramuccia Food and Drink, entrate
                a far parte della nostra famiglia. Siamo grati per il vostro
                sostegno e non vediamo l'ora di condividere con voi il nostro
                amore per le bevande creative, l'intrattenimento e l'atmosfera
                unica del nostro bar.
              </p>
              <hr />
              <p>
                Grazie per aver scelto di visitarci. Siamo ansiosi di
                accogliervi e di offrirvi un'esperienza indimenticabile.
              </p>
            </section>
          </div>
        </div>
        <footer id="contact">
          <div className="fade-in contact-row   text-center  fs-4 text-light ">
            <div className="py-1">
              <a
                target="_blank"
                href="https://instagram.com/scaramuccia_food_drink "
              >
                <AiOutlineInstagram />
              </a>
              <a target="_blank" href="https://www.facebook.com/scaramucciaa">
                <AiOutlineFacebook />
              </a>
              <a
                target="_blank"
                href="https://maps.app.goo.gl/zRWzVXxgvmmtjcAV8"
              >
                <PiMapPinLineLight />
              </a>
              <a target="_blank" href="">
                <BsTelephone />
              </a>
            </div>
          </div>
          <div className="credit text-center pb-1">
            Created By:{" "}
            <a href="https://antoniocancemiportfolio.web.app/" target="_blank">
              Antonio Cancemi
            </a>
          </div>
        </footer>
      </Container>
    </>
  );
};
export default HomePage;
