import CaretogyRowList from "../components/CaretogyRowList";
import Hero from "../components/Hero";
import MenuItemCard from "../components/MenuItemCard";
import TitleRowCategory from "../components/TitleRowCategory";
import { AiOutlineArrowUp, AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import menu from "../menu.json";
const MenuPage = () => {
  const [arrowToTop, setArrowToTop] = useState("d-none");
  const [showProduct, setShowProduct] = useState(false);
  const [productContent, setProductContent] = useState(null);
  const categorie = Object.keys(menu);

  const handleProductModalShow = () => {
    setShowProduct(!showProduct);
    if (showProduct) {
      setTimeout(400, setProductContent(null));
    }
  };
  window.addEventListener("scroll", function () {
    let scrollY = this.window.scrollY;
    if (scrollY > 350) {
      setArrowToTop(true);
    } else if (scrollY < 350 && arrowToTop != "d-none") {
      setArrowToTop(false);
    }
  });

  return (
    <Container className="main-container fade-in p-0">
      <span id="top"></span>
      <div
        className={`d-flex justify-content-center arrow-to-top text-center ${
          arrowToTop ? "show" : "hide"
        } ${arrowToTop === "d-none" ? "d-none" : ""}`}
      >
        <a href="#top">
          <AiOutlineArrowUp />
        </a>
      </div>
      <Hero />
      <CaretogyRowList categorie={categorie} />
      <div className="menu-main-container  box-shadow ">
        {Object.keys(menu).map((categoria, index) => (
          <>
            <div id={categoria} className="id"></div>
            <TitleRowCategory categoria={categoria} key={index} />
            {/* product card */}
            {menu[categoria].map((item, index) => (
              <MenuItemCard
                key={index}
                content={item}
                handleProductModalShow={handleProductModalShow}
                setProductContent={setProductContent}
              />
            ))}
          </>
        ))}
      </div>
      {productContent ? (
        <div
          className={` product-modal d-flex align-items-center ${
            showProduct ? "showPM" : "hidePM"
          }`}
        >
          <div className="modal-body">
            <div className="image-container  d-flex justify-content-center">
              <img
                className="box-shadow bg-light"
                src={
                  productContent?.imageUrl.length > 5
                    ? productContent?.imageUrl
                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMz1eVzkDwCacSu0buRgGCaIhnrUlM9NHWyA&usqp=CAU"
                }
                alt=""
              />
            </div>
            <div className="product-info-container">
              <br />
              <br />
              <h3 className="fw-bold">{productContent?.name}</h3>
              {productContent?.description === "..." ? (
                <></>
              ) : (
                <p className="border-start border-2 ps-1">
                  <span>Descrizione:</span>
                  <br />
                  <span className="text-dark fs-5 ">
                    {productContent?.description}
                  </span>
                </p>
              )}
              <h4 className="text-end">
                € {productContent?.price}
                {productContent?.price.toString.length < 3 &&
                productContent?.price.toString().includes(".")
                  ? "0"
                  : ",00"}
              </h4>
            </div>
            <br />
            <div className="modal-heder d-flex justify-content-center">
              <Button
                onClick={handleProductModalShow}
                variant={"trasparent"}
                className="regular-shadow d-flex align-items-center justify-content-center "
              >
                <AiOutlineClose />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
};
export default MenuPage;
