const Hero = () => {
  return (
    <div className="hero-container box-shadow d-flex  ">
      <h1>Menu</h1>
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcST8uEv9NF6gwUzuMO22D74OoqyLLHVop28wg&usqp=CAU"
        alt=""
      />
    </div>
  );
};
export default Hero;
