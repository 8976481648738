import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import MenuPage from "./pages/MenuPage";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import BarChallenge from "./pages/BarChallenge";

function App() {
  return (
    <Router>
      <div className="all mx-auto">
        <NavBar />
        <div id="top"></div>
        <Routes>
          {/* <Route path="/" element={<MainPage />}></Route> */}
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/menu" element={<MenuPage />}></Route>
          <Route path="/challenge" element={<BarChallenge />}></Route>
        </Routes>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
