import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";

function GalleryCarousel({ imageList }) {
  const navigate = useNavigate();

  return (
    <Carousel
      onClick={() => navigate("/menu")}
      indicators={false}
      controls={false}
      fade
      className="carousel-container mx-auto  "
    >
      {imageList?.map((image, index) => (
        <Carousel.Item key={index} interval={6000}>
          <div className="d-flex justify-content-center">
            <img height={200} src={image} alt="" />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default GalleryCarousel;
