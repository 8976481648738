const CaretogyRowList = ({ categorie }) => {
  return (
    <div className="top-category-container sticky-top box-shadow ">
      <div className="w-100 text-center">
        <h2 className="p-0 m-0">Categorie</h2>
      </div>
      <div className="category-list-row    ">
        {categorie?.map((item, index) => (
          <div key={index}>
            <a
              className="regular-shadow category-button border"
              href={`#${item}`}
            >
              {item}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
export default CaretogyRowList;
