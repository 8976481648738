const MenuItemCard = ({
  handleProductModalShow,
  setProductContent,
  content,
}) => {
  const combineModalfunc = () => {
    handleProductModalShow();
    setProductContent(content);
  };
  return (
    <div
      onClick={combineModalfunc}
      className="menu-item-conatiner box-shadow  d-flex"
    >
      <div className="menu-item-image-container">
        <img
          width={80}
          className="menu-item-image "
          src={
            content?.imageUrl.length > 5
              ? content?.imageUrl
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMz1eVzkDwCacSu0buRgGCaIhnrUlM9NHWyA&usqp=CAU"
          }
          alt=""
        />
      </div>
      <div className="menu-item-text-container ">
        <h4>
          {content?.name.length >= 26
            ? content?.name.slice(0, 25) + "..."
            : content?.name}
        </h4>
        {content?.description !== "..." ? (
          <p>
            {content?.description.length >= 60
              ? content?.description.slice(0, 60) + "..."
              : content?.description}
          </p>
        ) : (
          <p></p>
        )}
        <div className="d-flex justify-content-end ">
          <span>
            € {content?.price}
            {content?.price.toString.length < 3 &&
            content?.price.toString().includes("." || ",")
              ? "0"
              : ",00"}
          </span>
          {/* <span className=" d-flex align-items-center">
            4.2
            <AiFillStar />
          </span> */}
        </div>
      </div>
    </div>
  );
};
export default MenuItemCard;
