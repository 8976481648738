import { useState } from "react";
import { Button, Container } from "react-bootstrap";

const BarChallenge = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null);
  const sfidaCasuale = selezionaSfidaCasuale(sfideAlcolicheDivertenti);
  function selezionaSfidaCasuale(sfide) {
    const indiceCasuale = Math.floor(Math.random() * sfide.length);
    return sfide[indiceCasuale];
  }
  const handleChange = () => {
    setShow(false);
    setContent(null);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShow(true);
      setContent(sfidaCasuale);
    }, 2000);
  };

  return (
    <div className="fade-in d-flex flex-column challenge-page justify-content-center align-items-center">
      <div>
        <Button
          onClick={handleChange}
          variant="trasparent"
          className="challenge-button"
        >
          START
        </Button>
      </div>
      <div className={" box-challenge " + (show ? "box-shadow fade-in" : "")}>
        <div className="d-flex align-items-center h-100">
          {show ? <p className="fade-in">{content}</p> : <></>}
        </div>
        {loading ? (
          <div class="bouncing-loader fade-in ">
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default BarChallenge;
const sfideAlcolicheDivertenti = [
  "Inventa un nuovo nome per te stesso e comportati come se fossi una celebrità per la serata.",
  "Fai una danza improvvisata al centro del bar per almeno 30 secondi.",
  "Scambia posto con un amico e cerca di convincere gli altri che sei loro per 5 minuti.",
  "Fai una telefonata a un amico e raccontagli una storia assurda senza ridere.",
  "Scegli un oggetto nel bar e crea una storia stravagante su di esso.",
  "Ordina un cocktail usando un accento straniero per l'intera serata.",
  "Scegli un brano a caso dal tuo telefono e balla in mezzo al bar quando inizia a suonare.",
  "Indossa un oggetto o accessorio stravagante, come una parrucca o degli occhiali da sole, per il resto della serata.",
  "Cerca di convincere tutti che hai un talento segreto e mettilo in mostra.",
  "Scambia una bevanda con un estraneo e condividi una breve conversazione.",
  // Ecco altre sfide:
  "Ordina un cocktail con un nome strano e cerca di spiegare al barista perché lo hai scelto.",
  "Scegli un oggetto nel bar e crea una canzone improvvisata su di esso.",
  "Canta una canzone a cappella davanti a tutti nel bar.",
  "Fai una lista di parole difficili da pronunciare e chiedi ai tuoi amici di bere abbastanza prima di tentare di dirle.",
  "Ordina un cocktail con un ombrellino e cerca di fare un discorso serio mentre lo usi.",
  "Crea una storia ridicola su come hai incontrato il tuo drink e raccontala agli altri.",
  "Ordina una bevanda colorata e fingi di essere un chimico spiegando la scienza dietro il tuo drink.",
  "Chiedi ai tuoi amici di darti un argomento casuale e improvvisa una poesia su di esso.",
  "Fai un selfie con uno sconosciuto e condividilo sui social media con una didascalia divertente.",
  "Sfida qualcuno a una gara di limbo improvvisata usando una cannuccia come asta.",
  "Ordina un drink e cerca di fare una foto artistica/trash con che riprenda te e il cocktail prima di berlo.",
  "Inventa una storia stravagante su chi ha inventato il primo cocktail e raccontala agli altri.",
  "Ordina un drink e cerca di bere il tuo bicchiere a testa in giù senza usare le mani.",
  "Cerca di fare un discorso emotivo sul tuo amore per le bevande alcoliche.",
];
